#SelectConnectTypeCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 24px 20px;
  border-radius: 12px;
  cursor: pointer;
  transition: box-shadow ease-in 140ms;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    box-shadow: 0 0 12px 0 rgba(36, 0, 82, 0.16);
  }

  .connect-type-text-wrapper {
    display: flex;
    flex-direction: column;

    .connect-type-main-text {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.41px;
    }

    .connect-type-sub-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .connect-type-icon {
    width: 50px;
    object-fit: contain;
  }
}
