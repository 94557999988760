#ConnectByWhisper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .high-emphasis {
        color: var(--TextIcon_OnSurface_HighEmp);
    }

    .connect-by-whisper-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        white-space: pre-wrap;
        text-align: center;
    }

    .qr-code-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 165px;
        height: 165px;
        background-color: var(--Surface_Brand_QR);
        border-radius: 12px;

        canvas {
            border-radius: 16px;
        }
    }

    .valid-timer {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            font-size: 20px;
        }

        .valid-time {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .connect-by-whisper-comment {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        white-space: pre-wrap;
    }
}
