@import 'src/common/styles/mixins';

.need-oauth-permission {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    //@media (min-width: 421px) {
    //  padding: 0 20px;
    //}

    &-top {
        display: flex;
        flex-direction: column;

        &-header {
            margin-bottom: 30px;
            word-break: break-all;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: var(--TextIcon_OnSurface_HighEmp);
        }

        &-service {
            display: flex;
            flex-direction: row;
            padding: 10px;
            margin-bottom: 45px;
            border-radius: 10px;
            background-color: var(--Surface_Normal_Container10);

            &-image {
                width: 36px;
                margin-right: 10px;
                object-fit: contain;
            }

            &-name {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                color: var(--TextIcon_OnSurface_HighEmp);
            }
        }

        &-scope {
            max-height: 220px;
            overflow: auto;
            padding: 0 20px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            /* Add a thumb */
            &::-webkit-scrollbar-thumb {
                border-radius: 20px;
                background-color: var(--Surface_Brand_Primary);
            }

            &-data {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }

                &-name {
                    margin-bottom: 4px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--TextIcon_OnSurface_HighEmp);
                }

                &-description {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--TextIcon_OnSurface_LowEmp);

                }
            }
        }
    }

    &-bottom {
        display: flex;
        flex-direction: column;

        &-button {
            width: 100%;
            height: 46px;
            margin-bottom: 14px;
            color: var(--TextIcon_OnPrimary_HighEmp);
            background-color: var(--Surface_Brand_Primary);
            border-radius: 10px;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
        }

        &-comment {
            word-break: break-all;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            color: var(--TextIcon_OnSurface_LowEmp);
        }
    }
}
