html[data-project="whisper"] {
  .connectTypeCompanyIcon {
    content: url("./whisper/Icon_connect_type_company.png");
  }

  .companyLogoWithText {
    content: url("./whisper/Image_company_logo_with_text.png");
  }

  .loadingIcon {
    content: url("./whisper/Loading_icon.gif");
  }

  .qrCompanyLogo {
    content: url("./whisper/QR_company_logo.png");
  }

  .appstoreIcon {
    content: url("./whisper/appstore.png");
  }

  .playstoreIcon {
    content: url("./whisper/playstore.png");
  }
}

html[data-project="EON"] {
  .connectTypeCompanyIcon {
    content: url("./eon/Icon_connect_type_company.png");
  }

  .companyLogoWithText {
    content: url("./eon/Image_company_logo_with_text.png");
  }

  .loadingIcon {
    content: url("./eon/Loading_icon.gif");
  }

  .qrCompanyLogo {
    content: url("./eon/QR_company_logo.png");
  }

  .appstoreIcon {
    content: url("./eon/appstore.png");
  }

  .playstoreIcon {
    content: url("./eon/playstore.png");
  }
}

html[data-project="bonego"] {
  .connectTypeCompanyIcon {
    content: url("./bonego/Icon_connect_type_company.png");
  }

  .companyLogoWithText {
    content: url("./bonego/Image_company_logo_with_text.png");
  }

  .loadingIcon {
    content: url("./bonego/Loading_icon.gif");
  }

  .qrCompanyLogo {
    content: url("./bonego/QR_company_logo.png");
  }

  .appstoreIcon {
    content: url("./bonego/appstore.png");
  }

  .playstoreIcon {
    content: url("./bonego/playstore.png");
  }
}
