@use "colors.whisper" as WhisperColors;
@use "colors.eon" as EONColors;
@use "colors.bonego" as BonegoColors;
// @use "colors.namu" as NamuColors;

html {
  --Surface_Brand_Primary: #{WhisperColors.$Surface_Brand_Primary};
  --Surface_Brand_QR: #{WhisperColors.$Surface_Brand_QR};
  --Surface_Normal_Container0: #{WhisperColors.$Surface_Normal_Container0};
  --Surface_Normal_Container10: #{WhisperColors.$Surface_Normal_Container10};
  --Surface_Normal_AbsoluteW: #{WhisperColors.$Surface_Normal_AbsoluteW};
  --TextIcon_OnSurface_HighEmp: #{WhisperColors.$TextIcon_OnSurface_HighEmp};
  --TextIcon_OnSurface_LowEmp: #{WhisperColors.$TextIcon_OnSurface_LowEmp};
  --TextIcon_OnPrimary_HighEmp: #{WhisperColors.$TextIcon_OnPrimary_HighEmp};
}

html[data-project="whisper"] {
  --Surface_Brand_Primary: #{WhisperColors.$Surface_Brand_Primary};
  --Surface_Brand_QR: #{WhisperColors.$Surface_Brand_QR};
  --Surface_Normal_Container0: #{WhisperColors.$Surface_Normal_Container0};
  --Surface_Normal_Container10: #{WhisperColors.$Surface_Normal_Container10};
  --Surface_Normal_AbsoluteW: #{WhisperColors.$Surface_Normal_AbsoluteW};
  --TextIcon_OnSurface_HighEmp: #{WhisperColors.$TextIcon_OnSurface_HighEmp};
  --TextIcon_OnSurface_LowEmp: #{WhisperColors.$TextIcon_OnSurface_LowEmp};
  --TextIcon_OnPrimary_HighEmp: #{WhisperColors.$TextIcon_OnPrimary_HighEmp};
}

html[data-project="EON"] {
  --Surface_Brand_Primary: #{EONColors.$Surface_Brand_Primary};
  --Surface_Brand_QR: #{EONColors.$Surface_Brand_QR};
  --Surface_Normal_Container0: #{EONColors.$Surface_Normal_Container0};
  --Surface_Normal_Container10: #{EONColors.$Surface_Normal_Container10};
  --Surface_Normal_AbsoluteW: #{EONColors.$Surface_Normal_AbsoluteW};
  --TextIcon_OnSurface_HighEmp: #{EONColors.$TextIcon_OnSurface_HighEmp};
  --TextIcon_OnSurface_LowEmp: #{EONColors.$TextIcon_OnSurface_LowEmp};
  --TextIcon_OnPrimary_HighEmp: #{EONColors.$TextIcon_OnPrimary_HighEmp};
}

html[data-project="bonego"] {
  --Surface_Brand_Primary: #{BonegoColors.$Surface_Brand_Primary};
  --Surface_Brand_QR: #{BonegoColors.$Surface_Brand_QR};
  --Surface_Normal_Container0: #{BonegoColors.$Surface_Normal_Container0};
  --Surface_Normal_Container10: #{BonegoColors.$Surface_Normal_Container10};
  --Surface_Normal_AbsoluteW: #{BonegoColors.$Surface_Normal_AbsoluteW};
  --TextIcon_OnSurface_HighEmp: #{BonegoColors.$TextIcon_OnSurface_HighEmp};
  --TextIcon_OnSurface_LowEmp: #{BonegoColors.$TextIcon_OnSurface_LowEmp};
  --TextIcon_OnPrimary_HighEmp: #{BonegoColors.$TextIcon_OnPrimary_HighEmp};
}

// html[data-project="namu"] {
//   --Surface_Brand_Primary: #{NamuColors.$Surface_Brand_Primary};
//   --Surface_Brand_QR: #{NamuColors.$Surface_Brand_QR};
//   --Surface_Normal_Container0: #{NamuColors.$Surface_Normal_Container0};
//   --Surface_Normal_Container10: #{NamuColors.$Surface_Normal_Container10};
//   --Surface_Normal_AbsoluteW: #{NamuColors.$Surface_Normal_AbsoluteW};
//   --TextIcon_OnSurface_HighEmp: #{NamuColors.$TextIcon_OnSurface_HighEmp};
//   --TextIcon_OnSurface_LowEmp: #{NamuColors.$TextIcon_OnSurface_LowEmp};
//   --TextIcon_OnPrimary_HighEmp: #{NamuColors.$TextIcon_OnPrimary_HighEmp};
// }
