.select-connect-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .main-image-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .main-logo {
      height: 20px;
      object-fit: contain;
    }

    .store-image-container {
      display: flex;

      .store-icon {
        &:not(:last-child) {
          margin-right: 14px;
        }

        width: 20px;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
}
