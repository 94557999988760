@use "common/styles/Colors";
@use "common/assets/images";
@import "common/styles/mixins";

@include font-face-TTF("Roboto", "Roboto-Bold", bold);
@include font-face-TTF("Roboto", "Roboto-Regular", normal);
@include font-face-TTF("Roboto", "Roboto-Medium", 500);
@include font-face-TTF("Roboto", "Roboto-Black", 800);

html,
body {
    width: 100%;
    height: 100%;
    background-color: var(--Surface_Normal_Container0);
}

#root {
    margin: 0;
    font-family: -apple-system, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
}

input:focus {
    outline: none;
}

a {
    text-decoration: none;
}
