#SigningRequest {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .signing-request-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6px;

    .signing-request-text {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: var(--TextIcon_OnSurface_HighEmp);

      &.bold {
        font-weight: 500;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .signing-request-loading {
    width: 100px;
    margin: 16px auto;
    object-fit: contain;
  }

  .whisper-logo {
    height: 20px;
    object-fit: contain;
  }
}
